import { Component, OnInit } from '@angular/core';
import {
  NbToastrService,
  NbDialogService,
} from '../../common/public_api';
import { AddRoleModalComponent } from './add-role-modal/add-role-modal.component';
import { MENU_ITEMS } from '../../pages-menu';
import { IRole, AuthService } from '../../auth/services/auth.service';
import * as moment from 'moment';
import * as _ from 'lodash';

@Component({
  selector: 'ngx-roles',
  templateUrl: './roles.component.html',
  styleUrls: ['./roles.component.scss'],
})
export class RolesComponent implements OnInit {

  private menu = MENU_ITEMS;
  public defaultColumns = [
    'Role #',
    'Name',
    'Description',
    'Disabled',
    'EffectiveFromDateTime',
    'EffectiveToDateTime',
    'Action'
  ];
  public loading: boolean = false;
  public roles: IRole[] = [];
  public totalRoles: number = 0;

  constructor(
    private authService: AuthService,
    private dialogService: NbDialogService,
    private ToastService: NbToastrService,
    ) {}

  public ngOnInit() {
    this.loading = true;
    this.populateRoles();
  }

  private populateRoles() {
    this.authService.getRoles()
      .then(response => {
        this.roles = response.items;
        this.totalRoles = response.items.length;
      })
      .catch(error => console.log(error))
      .finally(() => this.loading = false);
  }

  public getDateFromDateTime(date: Date): string {
    if (_.isNil(date)) {
      return null;
    }

    return moment(date).format('MMM DD, YYYY');
  }

  public disableRole(role: IRole): void {
    //Will Implement on service side

    const roleToDisable = this.roles.find(roleItem => roleItem.roleDBID === role.roleDBID)

    if (!_.isNil(roleToDisable)) {
      roleToDisable.disabledInd = !role.disabledInd
    }
  }

  public addRole() {
    this.dialogService.open(AddRoleModalComponent);
  }

  public editRole(row: any) {
    this.dialogService.open(AddRoleModalComponent, {
      context: {
        roleId: row.data.roleId,
        isEditing: true,
      },
    });
  }

  public deleteRole(row: any) {
    this.ToastService.danger('Cannot Delete Role!');
  }
}
