import { Injector, ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { HttpRequest } from '@angular/common/http';

import {
  NbAlertModule,
  NbButtonModule,
  NbCardModule,
  NbCheckboxModule,
  NbIconModule,
  NbInputModule,
  NbLayoutModule,
} from '../common/public_api';

import { AuthService } from './services/auth.service';
import { AuthSimpleToken, AuthTokenClass } from './services/token/token';
import { TokenLocalStorage, TokenStorage } from './services/token/token-storage';
import { TokenService } from './services/token/token.service';
import { AuthTokenParceler, AUTH_FALLBACK_TOKEN } from './services/token/token-parceler';
import { AuthStrategy } from './strategies/auth-strategy';
import { AuthStrategyOptions } from './strategies/auth-strategy-options';
import { OAuth2AuthStrategy } from './strategies/oauth2/oauth2-strategy';
import { PasswordAuthStrategy } from './strategies/password/password-strategy';
import { LookupService } from './services/lookup.service';
import { DoctorService } from './services/doctor.service';
import { PatientService } from './services/patient.service';
import { BlogService } from './services/blog.service';
import { SurveyService } from './services/survey.service';

import {
  defaultAuthOptions,
  AUTH_INTERCEPTOR_HEADER,
  AUTH_OPTIONS,
  AUTH_STRATEGIES,
  AUTH_TOKEN_INTERCEPTOR_FILTER,
  AUTH_TOKENS,
  AUTH_USER_OPTIONS,
  AuthOptions,
  AuthStrategyClass,
} from './auth.options';

import { NbAuthComponent } from './auth.component';

import { NbAuthBlockComponent } from './auth-block/auth-block.component';
import { LoginComponent } from './login/login.component';
import { NbRegisterComponent } from './register/register.component';
import { NbLogoutComponent } from './logout/logout.component';
import { NbRequestPasswordComponent } from './request-password/request-password.component';
import { NbResetPasswordComponent } from './reset-password/reset-password.component';

import { deepExtend } from './helpers';
import { AuthRoutingModule } from './auth-routing.module';

export function strategiesFactory(options: AuthOptions, injector: Injector): AuthStrategy[] {
  const strategies = [];
  options.strategies
    .forEach(([strategyClass, strategyOptions]: [AuthStrategyClass, AuthStrategyOptions]) => {
      const strategy: AuthStrategy = injector.get(strategyClass);
      strategy.setOptions(strategyOptions);

      strategies.push(strategy);
    });
  return strategies;
}

export function tokensFactory(strategies: AuthStrategy[]): AuthTokenClass[] {
  const tokens = [];
  strategies
    .forEach((strategy: AuthStrategy) => {
      tokens.push(strategy.getOption('token.class'));
    });
  return tokens;
}

export function optionsFactory(options) {
  return deepExtend(defaultAuthOptions, options);
}

export function noOpInterceptorFilter(req: HttpRequest<any>): boolean {
  return true;
}

@NgModule({
  imports: [
    CommonModule,
    NbLayoutModule,
    NbCardModule,
    NbCheckboxModule,
    NbAlertModule,
    NbInputModule,
    NbButtonModule,
    RouterModule,
    FormsModule,
    NbIconModule,
    AuthRoutingModule,
  ],
  declarations: [
    NbAuthComponent,
    NbAuthBlockComponent,
    LoginComponent,
    NbRegisterComponent,
    NbRequestPasswordComponent,
    NbResetPasswordComponent,
    NbLogoutComponent,
  ],
  exports: [
    NbAuthComponent,
    NbAuthBlockComponent,
    LoginComponent,
    NbRegisterComponent,
    NbRequestPasswordComponent,
    NbResetPasswordComponent,
    NbLogoutComponent,
  ],
})
export class NbAuthModule {
  static forRoot(authOptions?: AuthOptions): ModuleWithProviders {
    return <ModuleWithProviders> {
      ngModule: NbAuthModule,
      providers: [
        { provide: AUTH_USER_OPTIONS, useValue: authOptions },
        { provide: AUTH_OPTIONS, useFactory: optionsFactory, deps: [AUTH_USER_OPTIONS] },
        { provide: AUTH_STRATEGIES, useFactory: strategiesFactory, deps: [AUTH_OPTIONS, Injector] },
        { provide: AUTH_TOKENS, useFactory: tokensFactory, deps: [AUTH_STRATEGIES] },
        { provide: AUTH_FALLBACK_TOKEN, useValue: AuthSimpleToken },
        { provide: AUTH_INTERCEPTOR_HEADER, useValue: 'Authorization' },
        { provide: AUTH_TOKEN_INTERCEPTOR_FILTER, useValue: noOpInterceptorFilter },
        { provide: TokenStorage, useClass: TokenLocalStorage },
        AuthTokenParceler,
        AuthService,
        TokenService,
        PasswordAuthStrategy,
        OAuth2AuthStrategy,
        LookupService,
        DoctorService,
        PatientService,
        BlogService,
        SurveyService,
      ],
    };
  }
}
