import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AppService } from './app/app.service';

export interface IBlog {
  blogID: number;
  title: string;
  article: string;
  publishDate?: Date;
  bannerImage: string;
  authorName: string;
  authorIdentityID: number;
  categoryLookup?: number;
  enabled?: boolean;
}

export interface IPublishBlogRequest {
  blogID: number;
  enabled: boolean;
  isAdmin: boolean;
}

export interface IPublishBlogResponse {
  enabled: boolean;
}

@Injectable()
export class BlogService {
  public apiPath: string = 'http://localhost:63753/api/v1/blog';

  constructor(public Http: HttpClient,private appService: AppService) {}

  public getBlogs(query: string = ''): Promise<IBlog[]> {
    return this.Http.get<IBlog[]>(`${this.appService.apiUrl}blog/bloglistforadmin` + query)
      .toPromise();
  }

  public publish(request: IPublishBlogRequest): Promise<IPublishBlogResponse> {
    return this.Http.post<IPublishBlogResponse>(`${this.appService.apiUrl}blog/publishBlog`, request)
      .toPromise();
  }
}
