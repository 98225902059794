import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import {
  NbButtonModule,
  NbCardModule,
  NbProgressBarModule,
  NbTabsetModule,
  NbUserModule,
  NbIconModule,
  NbSelectModule,
  NbListModule,
  NbMenuModule,
  NbSidebarModule,
  NbTreeGridModule,
  NbInputModule,
  NbCheckboxModule,
  NbDatepickerModule,
  NbSpinnerModule,
} from '../../common/public_api';

import { NgxEchartsModule } from 'ngx-echarts';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { NgxCommonModule } from '../../common/ngx-common.module';
import { RolesComponent } from './roles.component';
import { AddRoleModalComponent } from './add-role-modal/add-role-modal.component';
import { RouterModule } from '@angular/router';

@NgModule({
  imports: [
    NgxCommonModule,
    NbCardModule,
    NbUserModule,
    NbButtonModule,
    NbIconModule,
    NbTabsetModule,
    NbSelectModule,
    NbListModule,
    NbTreeGridModule,
    NbProgressBarModule,
    NgxEchartsModule,
    NgxChartsModule,
    NbMenuModule,
    NbSidebarModule,
    RouterModule,
    NbInputModule,
    NbCheckboxModule,
    FormsModule,
    NbDatepickerModule,
    NbSpinnerModule,
    ReactiveFormsModule,
  ],
  declarations: [
    RolesComponent,
    AddRoleModalComponent,
  ],
  entryComponents: [
    AddRoleModalComponent,
  ],
  providers: [],
})
export class RolesModule { }
