import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { JwtHelperService } from '@auth0/angular-jwt';
@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor( private router: Router){}
  public  jwtHelper = new JwtHelperService();
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      if(!this.hasValidAuthenticationToken()){
        this.router.navigate(['/auth/login'] );
        return false
      }
      return this.hasValidAuthenticationToken();
  }
  public hasValidAuthenticationToken(): boolean {
    try {
      const authenticationToken = localStorage.getItem('authenticatedByLoginToken')
      return authenticationToken !== null && !this.jwtHelper.isTokenExpired(authenticationToken)
    } catch (err) {
      return false
    }
  }

}
