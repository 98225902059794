import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject
} from '@angular/core';
import {
  Router
} from '@angular/router';
import {
  AUTH_OPTIONS,
  AuthSocialLink
} from '../auth.options';
import {
  getDeepFromObject
} from '../helpers';

import {
  AuthService
} from '../services/auth.service';
import {
  AuthResult
} from '../services/auth-result';
import { JwtHelperService } from '@auth0/angular-jwt';
import * as CryptoJS from 'crypto-js';

@Component({
  selector: 'nb-login',
  templateUrl: './login.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoginComponent {


  jwtHelper = new JwtHelperService()

  redirectDelay: number = 0;
  showMessages: any = {};
  strategy: string = '';

  errors: string[] = [];
  messages: string[] = [];
  user: any = {};
  submitted: boolean = false;
  socialLinks: AuthSocialLink[] = [];
  rememberMe = false;
  notAdmin: boolean = false;

  constructor(protected service: AuthService,
    @Inject(AUTH_OPTIONS) protected options = {},
    protected cd: ChangeDetectorRef,
    protected router: Router) {

    this.redirectDelay = this.getConfigValue('forms.login.redirectDelay');
    this.showMessages = this.getConfigValue('forms.login.showMessages');
    this.strategy = this.getConfigValue('forms.login.strategy');
    this.socialLinks = this.getConfigValue('forms.login.socialLinks');
    this.rememberMe = this.getConfigValue('forms.login.rememberMe');
  }
  // Declare this key and iv values in declaration
      key = CryptoJS.enc.Utf8.parse('4512631236589784');
      iv = CryptoJS.enc.Utf8.parse('4512631236589784');
  encryptUsingAES256(plainText) {

    var encrypted = CryptoJS.AES.encrypt(CryptoJS.enc.Utf8.parse(plainText), this.key, {
        keySize: 128 / 8,
        iv: this.iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7
    });
    return encrypted;
}

  public login(): void {
    this.errors = [];
    this.messages = [];
    this.notAdmin = false
    this.submitted = true;
    let request = {
      email: this.user.email,
      password: this.encryptUsingAES256(this.user.password).toString()
    }

    this.service.authenticate(request)
      .subscribe((result: AuthResult) => {
        this.submitted = false;
        if (result.isSuccess()) {
          let token:any =  this.jwtHelper.decodeToken(localStorage.getItem('authenticatedByLoginToken'))
          if(token.Roles.some(r => r !== 'Admin')){
            localStorage.removeItem('authenticatedByLoginToken')
            this.notAdmin = true
          }else {
            this.messages = result.getMessages();
          }

        } else {
          this.errors = result.getErrors();
        }

        const redirect = result.getRedirect();
        if (redirect) {
          setTimeout(() => {
            return this.router.navigateByUrl('/doctors');
          }, this.redirectDelay);
        }
        this.cd.detectChanges();
      });
  }

  public getConfigValue(key: string): any {
    return getDeepFromObject(this.options, key, null);
  }
}
