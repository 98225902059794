import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import {
  NbButtonModule,
  NbCardModule,
  NbProgressBarModule,
  NbTabsetModule,
  NbUserModule,
  NbIconModule,
  NbSelectModule,
  NbListModule,
  NbMenuModule,
  NbSidebarModule,
  NbTreeGridModule,
  NbSpinnerModule,
  NbInputModule,
  NbCheckboxModule,
} from '../../common/public_api';

import { DoctorsComponent } from './doctors.component';
import { NgxCommonModule } from '../../common/ngx-common.module';
import { FsIconComponent } from '../../tables/tree-grid/tree-grid.component';
import { RouterModule } from '@angular/router';

@NgModule({
  imports: [
    NgxCommonModule,
    NbCardModule,
    NbUserModule,
    NbButtonModule,
    NbIconModule,
    NbTabsetModule,
    NbSelectModule,
    NbListModule,
    NbTreeGridModule,
    NbProgressBarModule,
    NbMenuModule,
    NbSidebarModule,
    RouterModule,
    NbSpinnerModule,
    NbInputModule,
    NbCheckboxModule,
    FormsModule,
    ReactiveFormsModule,
  ],
  declarations: [
    DoctorsComponent,
    FsIconComponent,
  ],
  providers: [],
})
export class DoctorsModule { }
