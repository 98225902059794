import { Inject, Injectable } from '@angular/core';
import { Observable, of as observableOf } from 'rxjs';
import { switchMap, map } from 'rxjs/operators';
import { AuthStrategy } from '../strategies/auth-strategy';
import { AuthResult } from './auth-result';
import { TokenService } from './token/token.service';
import { AuthToken } from './token/token';
import { PasswordAuthStrategy } from '../strategies/password/password-strategy';
import { PasswordAuthStrategyOptions, passwordStrategyOptions } from '../strategies/password/password-strategy-options';
import { HttpClient } from '@angular/common/http';
import { AppService } from './app/app.service';

export interface IRole {
  roleDBID: number;
  roleName: string;
  roleDesc: string;
  disabledInd: boolean;
  effectiveFromDateTime: string;
  effectiveToDateTime?: string;
}

export interface IRight {
  rightDBID: number;
  rightName: string;
  rightDesc: string;
  rightNotes: string;
}

@Injectable()
export class AuthService {

  public apiPath: string = 'http://localhost:63753/api/v1';

  constructor(protected tokenService: TokenService,
    protected passwordAuthStrategy: PasswordAuthStrategy,
    public Http: HttpClient,
     private appService: AppService) {}

  getToken(): Observable < AuthToken > {
    return this.tokenService.get();
  }

  isAuthenticated(): Observable < boolean > {
    return this.getToken()
      .pipe(map((token: AuthToken) => token.isValid()));
  }

  public isAuthenticatedOrRefresh(): Observable < boolean > {
    return this.getToken()
      .pipe(
        switchMap(token => {
          if (token.getValue() && !token.isValid()) {
            return this.refreshToken(token.getOwnerStrategyName(), token)
              .pipe(
                switchMap(res => {
                  if (res.isSuccess()) {
                    return this.isAuthenticated();
                  } else {
                    return observableOf(false);
                  }
                }),
              );
          } else {
            return observableOf(token.isValid());
          }
        }));
  }

  public onTokenChange(): Observable < AuthToken > {
    return this.tokenService.tokenChange();
  }


  public onAuthenticationChange(): Observable < boolean > {
    return this.onTokenChange()
      .pipe(map((token: AuthToken) => token.isValid()));
  }


  public authenticate(data?: any): Observable < AuthResult > {
    return this.passwordAuthStrategy.authenticate(data)
      .pipe(
        switchMap((result: AuthResult) => {
          return this.processResultToken(result);
        }),
      );
  }


  public register(strategyName: string, data?: any): Observable < AuthResult > {
    return this.getStrategy(strategyName).register(data)
      .pipe(
        switchMap((result: AuthResult) => {
          return this.processResultToken(result);
        }),
      );
  }

  public logout(): Observable < AuthResult > {
    return this.passwordAuthStrategy.logout()
      .pipe(
        switchMap((result: AuthResult) => {
          if (result.isSuccess()) {
            this.tokenService.clear()
              .pipe(map(() => result));
          }
          return observableOf(result);
        }),
      );
  }

  public requestPassword(strategyName: string, data?: any): Observable < AuthResult > {
    return this.getStrategy(strategyName).requestPassword(data);
  }


  public resetPassword(strategyName: string, data?: any): Observable < AuthResult > {
    return this.getStrategy(strategyName).resetPassword(data);
  }


  public refreshToken(strategyName: string, data?: any): Observable < AuthResult > {
    return this.getStrategy(strategyName).refreshToken(data)
      .pipe(
        switchMap((result: AuthResult) => {
          return this.processResultToken(result);
        }),
      );
  }

  public getRoles(): Promise<{items: IRole[]; count: number}> {
    return this.Http.get<{items: IRole[]; count: number}>(`${this.appService.apiUrl}authorization/roles`)
      .toPromise();
  }

  public getRights(): Promise<{items: IRight[]; count: number}> {
    return this.Http.get<{items: IRight[]; count: number}>(`${this.appService.apiUrl}authorization/rights`)
      .toPromise();
  }

  protected getStrategy(strategyName: string): AuthStrategy {
    return this.passwordAuthStrategy;
    // const found = this.strategies.find((strategy: AuthStrategy) => strategy.getName() === strategyName);

    // if (!found) {
    //   throw new TypeError(`There is no Auth Strategy registered under '${strategyName}' name`);
    // }

    // return found;
  }

  private processResultToken(result: AuthResult) {
    if (result.isSuccess() && result.getToken()) {
      return this.tokenService.set(result.getToken())
        .pipe(
          map((token: AuthToken) => {
            return result;
          }),
        );
    }

    return observableOf(result);
  }
}
