import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import {
  NbButtonModule,
  NbCardModule,
  NbProgressBarModule,
  NbTabsetModule,
  NbUserModule,
  NbIconModule,
  NbSelectModule,
  NbListModule,
  NbTreeGridModule,
  NbMenuModule,
  NbSpinnerModule,
  NbInputModule,
} from '../../common/public_api';
import { NgxEchartsModule } from 'ngx-echarts';
import { NgxChartsModule } from '@swimlane/ngx-charts';

import { NgxCommonModule } from '../../common/ngx-common.module';
import { RightsComponent } from './rights.component';
import { RouterModule } from '@angular/router';

@NgModule({
  imports: [
    NgxCommonModule,
    NbCardModule,
    NbUserModule,
    NbButtonModule,
    NbIconModule,
    NbTabsetModule,
    NbSelectModule,
    NbListModule,
    NbTreeGridModule,
    NbProgressBarModule,
    NgxEchartsModule,
    NgxChartsModule,
    NbMenuModule,
    RouterModule,
    NbSpinnerModule,
    NbInputModule,
    FormsModule,
    ReactiveFormsModule,
  ],
  declarations: [
    RightsComponent,
  ],
  providers: [],
})
export class RightsModule { }
