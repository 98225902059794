import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import { CoreModule } from './@core/core.module';
import { NgxCommonModule } from './common/ngx-common.module';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import {
  NbChatModule,
  NbDatepickerModule,
  NbDialogModule,
  NbMenuModule,
  NbSidebarModule,
  NbToastrModule,
  NbWindowModule,
} from './common/public_api';

import { ModalOverlaysModule } from './modal-overlays/modal-overlays.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { DashboardModule } from './dashboard/dashboard.module';
import { ECommerceModule } from './e-commerce/e-commerce.module';
import { MiscellaneousModule } from './miscellaneous/miscellaneous.module';
import { NbEvaIconsModule } from './eva-icons/eva-icons.module';
import {
  TranslateModule,
  TranslateLoader,
  MissingTranslationHandler,
  MissingTranslationHandlerParams,
} from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { NbAuthModule } from './auth/auth.module';
import { AuthJWTInterceptor } from './auth/services/interceptors/jwt-interceptor';
import { RouterModule } from '@angular/router';
import { DoctorsModule } from './pages/doctors/doctors.module';
import { PatientsModule } from './pages/patients/patients.module';
import { RolesModule } from './pages/roles/roles.module';
import { RightsModule } from './pages/rights/rights.module';
import { LookupsModule } from './pages/lookups/lookups.module';
import { BlogsModule } from './pages/blogs/blogs.module';
import { PostsModule } from './pages/posts/posts.module';
import { SurveysModule } from './pages/surveys/surveys.module';
import { AppStartupService } from './auth/services/appStartup/app-startup.service';

export class LoggingMissingTranslationHandler implements MissingTranslationHandler {
  public handle(params: MissingTranslationHandlerParams): string {
    const lang = params.translateService.currentLang;
    if (lang) {
      console.error(`Missing translation for ${params.key} in ${lang}`);
    }
    return params.key;
  }
}
// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http, `/i18n/`, '.json');
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    RouterModule,
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    AppRoutingModule,
    NbMenuModule,
    DashboardModule,
    ECommerceModule,
    NbAuthModule.forRoot(),
    MiscellaneousModule,
    NbEvaIconsModule,
    DoctorsModule,
    PatientsModule,
    RolesModule,
    RightsModule,
    LookupsModule,
    BlogsModule,
    PostsModule,
    SurveysModule,
    FormsModule,
    ReactiveFormsModule,
    NgxCommonModule,
    ModalOverlaysModule,

    NbSidebarModule.forRoot(),
    NbMenuModule.forRoot(),
    NbDatepickerModule.forRoot(),
    NbDialogModule.forRoot(),
    NbWindowModule.forRoot(),
    NbToastrModule.forRoot(),
    NbChatModule.forRoot({
      messageGoogleMapKey: 'AIzaSyA_wNuCzia92MAmdLRzmqitRGvCF7wCZPY',
    }),
    CoreModule.forRoot(),
    TranslateModule.forRoot(
      {
        loader: {
          provide: TranslateLoader,
          useFactory: (HttpLoaderFactory),
          deps: [HttpClient],
        },
        missingTranslationHandler: {
          provide: MissingTranslationHandler,
          useClass: LoggingMissingTranslationHandler,
        },
      },
    ),
  ],
  exports: [
    LookupsModule,
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthJWTInterceptor,
      multi: true
    },
    AppStartupService,
    {
      provide: APP_INITIALIZER,
      useFactory: initialize,
      deps: [AppStartupService],
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
}
export function initialize(appStartupService: AppStartupService) {
  return () => appStartupService.initialize();
}
