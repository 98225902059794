import { Component, EventEmitter, Input, Output, Type } from '@angular/core';

import { NbCalendarCell, NbCalendarSize, NbCalendarViewMode } from '../calendar-kit/model';
import { NbDateService } from '../calendar-kit/services/date.service';
import { NbCalendarRangeDayCellComponent, NbCalendarRangeYearCellComponent } from './calendar-range-cells';


export interface NbCalendarRange<D> {
  start: D;
  end?: D;
}

@Component({
  selector: 'nb-calendar-range',
  template: `
    <nb-base-calendar
      [date]="range"
      (dateChange)="onChange($event)"
      [min]="min"
      [max]="max"
      [filter]="filter"
      [startView]="startView"
      [boundingMonth]="boundingMonth"
      [dayCellComponent]="dayCellComponent"
      [monthCellComponent]="monthCellComponent"
      [yearCellComponent]="yearCellComponent"
      [visibleDate]="visibleDate"
      [showHeader]="showHeader"
      [size]="size"
    ></nb-base-calendar>
  `,
})
export class NbCalendarRangeComponent<D> {

  @Input() boundingMonth: boolean = true;

  @Input() startView: NbCalendarViewMode = NbCalendarViewMode.DATE;

  @Input() min: D;

  @Input() max: D;

  @Input() filter: (D) => boolean;

  @Input('dayCellComponent')
  set _cellComponent(cellComponent: Type<NbCalendarCell<D, NbCalendarRange<D>>>) {
    if (cellComponent) {
      this.dayCellComponent = cellComponent;
    }
  }
  dayCellComponent: Type<NbCalendarCell<D, NbCalendarRange<D>>> = NbCalendarRangeDayCellComponent;

  @Input() monthCellComponent: Type<NbCalendarCell<D, NbCalendarRange<D>>>;

  @Input('yearCellComponent')
  set _yearCellComponent(cellComponent: Type<NbCalendarCell<D, NbCalendarRange<D>>>) {
    if (cellComponent) {
      this.yearCellComponent = cellComponent;
    }
  }
  yearCellComponent: Type<NbCalendarCell<D, NbCalendarRange<D>>> = NbCalendarRangeYearCellComponent;

  @Input() size: NbCalendarSize = NbCalendarSize.MEDIUM;

  @Input() visibleDate: D;

  @Input() showHeader: boolean = true;

  @Input() range: NbCalendarRange<D>;

  @Output() rangeChange: EventEmitter<NbCalendarRange<D>> = new EventEmitter();

  constructor(protected dateService: NbDateService<D>) {
  }

  onChange(date: D) {
    this.initDateIfNull();
    this.handleSelected(date);
  }

  private initDateIfNull() {
    if (!this.range) {
      this.range = { start: null, end: null };
    }
  }

  private handleSelected(date: D) {
    if (this.selectionStarted()) {
      this.selectEnd(date);
    } else {
      this.selectStart(date);
    }
  }

  private selectionStarted(): boolean {
    const { start, end } = this.range;
    return start && !end;
  }

  private selectStart(start: D) {
    this.selectRange({ start });
  }

  private selectEnd(date: D) {
    const { start } = this.range;

    if (this.dateService.compareDates(date, start) > 0) {
      this.selectRange({ start, end: date });
    } else {
      this.selectRange({ start: date, end: start });
    }
  }

  private selectRange(range: NbCalendarRange<D>) {
    this.range = range;
    this.rangeChange.emit(range);
  }
}
