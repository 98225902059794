import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import 'rxjs/add/operator/do'

@Injectable()

export class AppStartupService {
  public config: IT2DConfig
  public constructor(
    private http: HttpClient
  ) { }

  public initialize(): Promise<IT2DConfig> {
    return this.http.get<IT2DConfig>('../../../../config.json')
      .do(result => {
        this.config = result
        this.loadLookups()
      })
      .toPromise()
  }


  public baseUrl(): string {
    const api = this.config["domain"]
    return api.endsWith('/') ? api : `${api}/`
  }

  // Gets a value of specified property in the configuration file
  public get(key: any): any {
    return this.config[key]
  }

  public get apiUrl(): string {
    return `${this.baseUrl()}${this.config.apiPath}/${this.config.apiVersion}/`
  }

  public get signalRHubUrl(): string {
    return `${this.baseUrl()}`
  }

  public get apiUrl2(): string {
    return `${this.baseUrl()}${this.config.apiPath}/${this.config.apiVersionV2}/`
  }

  public get signalRHub(): string {
    return `${this.signalRHubUrl}signalr/hubs`
  }

  public loadLookups(): any {
    return this.http.get(`${this.apiUrl}lookuplists/generic/en-us`)
      .subscribe(
        result => localStorage.setItem('LookupList', JSON.stringify(result)),
        error => console.log("error", error)
      )
  }
}

export interface IT2DConfig {
  protocol: string
  domain: string
  apiPath: string
  apiVersion: string
  apiVersionV2: string,
  uiDomain: string
}
