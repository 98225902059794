import { Injectable } from "@angular/core";
import { AppStartupService } from "../appStartup/app-startup.service";

@Injectable({
  providedIn: 'root'
})

export class AppService {

  constructor(private appStartupService: AppStartupService){
  }

  public get apiUrl() {
    return this.appStartupService.apiUrl
  }

  public get signalRHub() {
    return this.appStartupService.signalRHub
  }

  public get apiUrlV2(){
    return this.appStartupService.apiUrl2
  }

}
