import {
  Component,
  OnDestroy,
  OnInit,
} from '@angular/core';
import {
  Router
} from '@angular/router';
import {
  NbMenuService,
  NbMenuBag,
} from '../menu/menu.service';

import {
  NbMediaBreakpointsService,
} from '../../services/breakpoints.service';
import {
  NbThemeService,
} from '../../services/theme.service';
import {
  NbSidebarService,
} from '../sidebar/sidebar.service';

import {
  UserData,
} from '../../../@core/data/users';
import {
  LayoutService,
} from '../../../@core/utils/layout.service';

import {
  map,
  takeUntil,
} from 'rxjs/operators';
import {
  Subject,
} from 'rxjs';


@Component({
  selector: 'ngx-header',
  styleUrls: ['./header.component.scss'],
  templateUrl: './header.component.html',
})
export class HeaderComponent implements OnInit, OnDestroy {

  private destroy$: Subject < void > = new Subject < void > ();
  userPictureOnly: boolean = false;
  user: any;

  themes = [{
    value: 'default',
    name: 'Light',
  } ];

  currentTheme = 'default';

  userMenu = [{
    title: 'Profile',
  }, {
    title: 'Log out',
  }];

  constructor(
    protected router: Router,
    private sidebarService: NbSidebarService,
    private menuService: NbMenuService,
    private themeService: NbThemeService,
    private userService: UserData,
    private layoutService: LayoutService,
    private breakpointService: NbMediaBreakpointsService) {}

  public ngOnInit() {
    this.currentTheme = this.themeService.currentTheme;

    this.userService.getUsers()
      .pipe(takeUntil(this.destroy$))
      .subscribe((users: any) => this.user = users.nick);

    const {
      xl,
    } = this.breakpointService.getBreakpointsMap();
    this.themeService.onMediaQueryChange()
      .pipe(
        map(([, currentBreakpoint]) => currentBreakpoint.width < xl),
        takeUntil(this.destroy$),
      )
      .subscribe((isLessThanXl: boolean) => this.userPictureOnly = isLessThanXl);

    this.themeService.onThemeChange()
      .pipe(
        map(({
          name,
        }) => name),
        takeUntil(this.destroy$),
      )
      .subscribe(themeName => this.currentTheme = themeName);
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  changeTheme(themeName: string) {
    this.themeService.changeTheme(themeName);
  }

  onDropDownMenuItemClick(menuBag: NbMenuBag) {
    if(menuBag.item.title === 'Log out') {
      setTimeout(() => {
        localStorage.removeItem('authenticatedByLoginToken')
        return this.router.navigateByUrl('/auth/login');
      }, 0);
      return
    }
  }

  toggleSidebar(): boolean {
    this.sidebarService.toggle(true, 'menu-sidebar');
    this.layoutService.changeLayoutSize();

    return false;
  }

  navigateHome() {
    this.menuService.navigateHome();
    return false;
  }
}
