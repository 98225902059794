import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { Subject } from 'rxjs';
import { convertToBoolProperty } from '../helpers';

@Component({
  selector: 'nb-accordion',
  template: `
    <ng-content select="nb-accordion-item"></ng-content>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NbAccordionComponent {

  openCloseItems = new Subject<boolean>();

  @Input('multi')
  get multi(): boolean {
    return this.multiValue;
  }
  set multi(val: boolean) {
    this.multiValue = convertToBoolProperty(val);
  }

  private multiValue = false;

  openAll() {
    if (this.multi) {
      this.openCloseItems.next(false);
    }
  }

  closeAll() {
    this.openCloseItems.next(true);
  }
}
