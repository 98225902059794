import { Component, Input, OnInit } from '@angular/core';
import { NbDialogRef, NbToastrService } from '../../../common/public_api';
import { LookupService, ILookupList, ILookupListEntry, ILookupRequest } from '../../../auth/services/lookup.service';

@Component({
  selector: 'ngx-add-lookup-modal',
  templateUrl: 'add-lookup-modal.component.html',
  styleUrls: ['add-lookup-modal.component.scss'],
})
export class AddLookupModalComponent implements OnInit {
  @Input() public lookupTypes: string[];
  @Input() public lookupType: string;
  @Input() public lookup: ILookupListEntry;
  @Input() public isEditing?: any;

  public selectedLookupType: string;
  public lookupName: string;
  public lookupDescription: string;
  public isDisabled: boolean = false;

  public constructor(
    protected ref: NbDialogRef<AddLookupModalComponent>,
    public ToastService: NbToastrService,
    public LookupsService: LookupService) {
  }

  public ngOnInit() {
    this.selectedLookupType = this.lookupType;
    this.lookupName = this.isEditing ? this.lookup.name : '';
    this.lookupDescription = this.isEditing ? this.lookup.description : '';
    this.isDisabled = this.isEditing ? this.lookup.isDisabled : false;
  }

  public cancel() {
    this.ref.close();
  }

  public submit() {
    const request: ILookupRequest = {
      tableName: this.lookupType,
      name: this.lookupName,
      description: this.lookupDescription,
      isDisabled: this.isDisabled,
    };

    this.LookupsService.add(request)
      .then(() => {
        this.ToastService.success('Save Successful');
        this.ref.close();
      })
      .catch(() => this.ToastService.danger('Save Failed'));
  }

  public update() {
    const request: ILookupRequest = {
      tableName: this.lookupType,
      name: this.lookupName,
      description: this.lookupDescription,
      isDisabled: this.isDisabled,
      lookupIndex: this.lookup.lookupIndex,
    };

    this.LookupsService.update(request)
      .then(() => {
        this.ToastService.success('Updated Successful');
        this.ref.close();
      })
      .catch(() => this.ToastService.danger('Updated Failed'));
  }
}
