import { Component, OnInit } from '@angular/core';
import {
  NbToastrService,
  NbDialogService,
} from '../../common/public_api';
import { MENU_ITEMS } from '../../pages-menu';
import { IRight, AuthService } from '../../auth/services/auth.service';
import * as moment from 'moment';
import * as _ from 'lodash';

@Component({
  selector: 'ngx-rights',
  templateUrl: './rights.component.html',
  styleUrls: ['./rights.component.scss'],
})
export class RightsComponent implements OnInit {

  private menu = MENU_ITEMS;
  public defaultColumns = [
    'Right #',
    'Name',
    'Description',
    'Notes',
    'Action'
  ];
  public loading: boolean = false;
  public rights: IRight[] = [];
  public totalRights: number = 0;

  constructor(
    private authService: AuthService,
    private dialogService: NbDialogService,
    private ToastService: NbToastrService,
    ) {}

  public ngOnInit() {
    this.loading = true;
    this.populateRights();
  }

  private populateRights() {
    this.authService.getRights()
      .then(response => {
        this.rights = response.items;
        this.totalRights = response.items.length;
      })
      .catch(error => console.log(error))
      .finally(() => this.loading = false);
  }

  public addRight() {
    this.ToastService.danger('Cannot Add Right!');
  }

  public deletRight(right: IRight) {
    this.ToastService.danger('Cannot Delete Right!');
  }
}
