import { Component, OnInit, Input } from '@angular/core';
import { NbDialogRef, NbToastrService } from '../../../common/public_api';

interface IRole {
  roleId: number;
  name: string;
  effectiveFrom: any;
  effectiveTo?: any;
  isDisabled?: boolean;
}

@Component({
  selector: 'ngx-add-role-modal',
  templateUrl: 'add-role-modal.component.html',
  styleUrls: ['add-role-modal.component.scss'],
})
export class AddRoleModalComponent implements OnInit {
  @Input() isEditing: boolean;
  @Input() roleId: number;

  public role: IRole;
  public data: IRole[] = [
    { roleId: 1, name: 'admin', effectiveFrom: 'Sep 01, 2019', effectiveTo: 'Sep 18, 2020', isDisabled: false },
    { roleId: 2, name: 'doctor', effectiveFrom: 'Sep 05, 2019', effectiveTo: 'Sep 18, 2020', isDisabled: false },
    { roleId: 3, name: 'patient', effectiveFrom: 'Sep 10, 2019', effectiveTo: 'Sep 18, 2021', isDisabled: false },
    { roleId: 4, name: 'visitor', effectiveFrom: 'Sep 13, 2019', effectiveTo: 'Sep 18, 2050', isDisabled: true },
  ];

  public constructor(
    protected ref: NbDialogRef<AddRoleModalComponent>,
    private ToastService: NbToastrService,
    ) {}

  public ngOnInit() {
    this.role = this.isEditing
      ? this.data.find(role => role.roleId === this.roleId)
      : {
        roleId: 0,
        name: '',
        effectiveFrom: '',
        effectiveTo: '',
        isDisabled: false,
      };
  }

  public cancel() {
    this.ref.close();
  }

  public submit() {
    this.ToastService.success('Save Successful');
    this.ref.close();
  }

  public update() {
    this.ToastService.success('Updated Successful');
    this.ref.close();
  }
}
