import { Inject, Injectable } from '@angular/core';
import { NB_MEDIA_BREAKPOINTS } from '../ngx-common.options';

export interface NbMediaBreakpoint {
  name: string;
  width: number;
}

export const DEFAULT_MEDIA_BREAKPOINTS = [
  {
    name: 'xs',
    width: 0,
  },
  {
    name: 'is',
    width: 400,
  },
  {
    name: 'sm',
    width: 576,
  },
  {
    name: 'md',
    width: 768,
  },
  {
    name: 'lg',
    width: 992,
  },
  {
    name: 'xl',
    width: 1200,
  },
  {
    name: 'xxl',
    width: 1400,
  },
  {
    name: 'xxxl',
    width: 1600,
  },
];

@Injectable()
export class NbMediaBreakpointsService {

  private breakpointsMap: { [breakpoint: string]: number };

  constructor(@Inject(NB_MEDIA_BREAKPOINTS) private breakpoints) {
    this.breakpointsMap = this.breakpoints.reduce((res, b: NbMediaBreakpoint) => {
      res[b.name] = b.width;
      return res;
    }, {});
  }

  getByWidth(width: number): NbMediaBreakpoint {
    const unknown = { name: 'unknown', width: width };
    const breakpoints = this.getBreakpoints();

    return breakpoints
      .find((point: NbMediaBreakpoint, index: number) => {
        const next = breakpoints[index + 1];
        return width >= point.width && (!next || width < next.width);
      }) || unknown;
  }


  getByName(name: string): NbMediaBreakpoint {
    const unknown = { name: 'unknown', width: NaN };
    const breakpoints = this.getBreakpoints();

    return breakpoints.find((point: NbMediaBreakpoint) => name === point.name) || unknown;
  }

  getBreakpoints(): NbMediaBreakpoint[] {
    return this.breakpoints;
  }

  getBreakpointsMap(): { [breakpoint: string]: number } {
    return this.breakpointsMap;
  }
}
