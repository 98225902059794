
import { InjectionToken } from '@angular/core';
import { NbMediaBreakpoint } from './services/breakpoints.service';
import { NbJSThemeOptions } from './services/js-themes/theme.options';

export interface NbThemeOptions {
  name: string;
}

export const NB_THEME_OPTIONS = new InjectionToken<NbThemeOptions>('Theme Options');
export const NB_MEDIA_BREAKPOINTS = new InjectionToken<NbMediaBreakpoint[]>('Media Breakpoints');
export const NB_BUILT_IN_JS_THEMES = new InjectionToken<NbJSThemeOptions[]>('Built-in JS Themes');
export const NB_JS_THEMES = new InjectionToken<NbJSThemeOptions[]>('JS Themes');

export const NB_WINDOW = new InjectionToken<Window>('Window');
export const NB_DOCUMENT = new InjectionToken<Document>('Document');
