import { Component, EventEmitter, Input, Output, Type } from '@angular/core';

import { NbCalendarCell, NbCalendarSize, NbCalendarViewMode } from '../calendar-kit/model';

@Component({
  selector: 'nb-calendar',
  template: `
    <nb-base-calendar
      [boundingMonth]="boundingMonth"
      [startView]="startView"
      [date]="date"
      [min]="min"
      [max]="max"
      [filter]="filter"
      [dayCellComponent]="dayCellComponent"
      [monthCellComponent]="monthCellComponent"
      [yearCellComponent]="yearCellComponent"
      [size]="size"
      [visibleDate]="visibleDate"
      [showHeader]="showHeader"
      (dateChange)="dateChange.emit($event)"
    ></nb-base-calendar>
  `,
})
export class NbCalendarComponent<D> {

  @Input() boundingMonth: boolean = true;

  @Input() startView: NbCalendarViewMode = NbCalendarViewMode.DATE;

  @Input() min: D;

  @Input() max: D;

  @Input() filter: (D) => boolean;

  @Input() dayCellComponent: Type<NbCalendarCell<D, D>>;

  @Input() monthCellComponent: Type<NbCalendarCell<D, D>>;

  @Input() yearCellComponent: Type<NbCalendarCell<D, D>>;

  @Input() size: NbCalendarSize = NbCalendarSize.MEDIUM;

  @Input() visibleDate: D;

  @Input() showHeader: boolean = true;

  @Input() date: D;

  @Output() dateChange: EventEmitter<D> = new EventEmitter();
}
