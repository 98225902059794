import { Component, OnInit } from '@angular/core';
import {
  NbToastrService,
  NbDialogService,
} from '../../common/public_api';
import { MENU_ITEMS } from '../../pages-menu';
import * as moment from 'moment';
import * as _ from 'lodash';
import { ILookupList, LookupService } from '../../auth/services/lookup.service';
import { ISurvey, SurveyService, IPublishSurveyRequest } from '../../auth/services/survey.service';

@Component({
  selector: 'ngx-surveys',
  templateUrl: './surveys.component.html',
  styleUrls: ['./surveys.component.scss'],
})
export class SurveysComponent implements OnInit {

  private menu = MENU_ITEMS;
  public lookups: ILookupList[] = [];
  public defaultColumns = [
    'Survey Title',
    'Audience',
    'Start Date',
    'End Date',
    'Creator',
    'Status',
    'Action',
  ];

  public surveys: ISurvey[] = [];
  public copySurveys: ISurvey[] = [];
  public loading = false;
  public searchInput: string = '';

  public constructor(
    private dialogService: NbDialogService,
    private ToastService: NbToastrService,
    private surveyService: SurveyService,
    public LookupsService: LookupService
  ) { }

  public ngOnInit() {
    this.populateSurveys();
    this.populateLookups();
  }

  private populateSurveys() {
    this.loading = true;
    this.surveyService.getSurveyList()
      .then((response: any) => {
        this.surveys = response.items;
        this.copySurveys = response.items;
      })
      .catch(() => this.ToastService.danger('Failed to get Survey List'))
      .finally(() => this.loading = false);
  }

  private populateLookups() {
    this.LookupsService.get()
      .then(response => this.lookups = response)
      .catch(() => this.ToastService.danger('Failed to get Lookup list.'));
  }

  public publishSurvey(survey: ISurvey): void {
    this.loading = true;
    const request: IPublishSurveyRequest = {
      surveyID: survey.surveyID,
      surveyCreatorIdentityID: survey.surveyCreatorIdentityID,
      publish: true,
      isPublished: true,
      isPublishRequested: false
    }

    this.surveyService.publishSurveyByAdmin(request)
      .then(response => {
        if (response.completed) {
          this.ToastService.success('Published Successfully');
          this.populateSurveys();
        } else {
          this.ToastService.danger('Published Failed')
        }
      })
      .catch(() => this.ToastService.danger('Published Failed'))
      .finally(() => this.loading = false);
  }

  public archiveSurvey(survey: ISurvey): void {
    //Will DO
  }

  public getSurveyTypeNamyByLookup(lookupId): string {
    if (_.isEmpty(this.lookups) || _.isNil(lookupId)) {
      return null;
    }

    const types: any = this.lookups.find(item => item.name === 'SurveyType').listEntries
    const state = types.find(state => state.lookupIndex === lookupId)

    if (_.isNil(state)) {
      return null;
    }

    return state.name;
  }

  public clear() {
    this.searchInput = '';
    this.surveys = this.copySurveys;
  }

  public getDate(date: string | Date): any {
    if (_.isNil(date)) {
      return null;
    }

    return moment(date).format('DD/MM/YYYY');
  }

  public search() {
    this.surveys = this.copySurveys
      .filter(survey => survey.surveyTitle.toLowerCase().startsWith(this.searchInput.toLowerCase())
        || survey.surveyTitle.toLowerCase().endsWith(this.searchInput.toLowerCase()));
  }
}
