import { Component, EventEmitter, HostBinding, Input, OnInit, Output, Type } from '@angular/core';

import { YEARS_IN_VIEW } from '../calendar-kit/components/calendar-year-picker/calendar-year-picker.component';
import { NbCalendarCell, NbCalendarSize, NbCalendarViewMode } from '../calendar-kit/model';
import { NbDateService } from '../calendar-kit/services/date.service';

@Component({
  selector: 'nb-base-calendar',
  templateUrl: './base-calendar.component.html',
})
export class NbBaseCalendarComponent<D, T> implements OnInit {
  @Input() boundingMonth: boolean = true;

  @Input('startView') activeViewMode: NbCalendarViewMode = NbCalendarViewMode.DATE;

  @Input() min: D;

  @Input() max: D;

  @Input() filter: (D) => boolean;

  @Input() dayCellComponent: Type<NbCalendarCell<D, T>>;

  @Input() monthCellComponent: Type<NbCalendarCell<D, T>>;

  @Input() yearCellComponent: Type<NbCalendarCell<D, T>>;

  @Input() size: NbCalendarSize = NbCalendarSize.MEDIUM;

  @Input() visibleDate: D;

  @Input() showHeader: boolean = true;

  @Input() date: T;

  @Output() dateChange: EventEmitter<T> = new EventEmitter();

  constructor(protected dateService: NbDateService<D>) {
  }

  ngOnInit() {
    if (!this.visibleDate) {
      this.visibleDate = this.dateService.today();
    }
  }

  @HostBinding('class.medium')
  get medium() {
    return this.size === NbCalendarSize.MEDIUM;
  }

  @HostBinding('class.large')
    get large() {
    return this.size === NbCalendarSize.LARGE;
  }

  ViewMode = NbCalendarViewMode;

  setViewMode(viewMode: NbCalendarViewMode) {
    this.activeViewMode = viewMode;
  }

  setVisibleDate(visibleDate: D) {
    this.visibleDate = visibleDate;
  }

  prevMonth() {
    this.changeVisibleMonth(-1);
  }

  nextMonth() {
    this.changeVisibleMonth(1);
  }

  prevYears() {
    this.changeVisibleYear(-1);
  }

  nextYears() {
    this.changeVisibleYear(1);
  }

  navigateToday() {
    this.setViewMode(NbCalendarViewMode.DATE);
    this.visibleDate = this.dateService.today();
  }

  private changeVisibleMonth(direction: number) {
    this.visibleDate = this.dateService.addMonth(this.visibleDate, direction);
  }

  private changeVisibleYear(direction: number) {
    this.visibleDate = this.dateService.addYear(this.visibleDate, direction * YEARS_IN_VIEW);
  }
}
