import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { NbOverlayModule } from '../cdk/overlay/overlay.module';
import { NbContextMenuDirective } from './context-menu.directive';
import { ContextMenuComponent } from './context-menu.component';
import { NbMenuModule } from '../menu/menu.module';


@NgModule({
  imports: [CommonModule, NbOverlayModule, NbMenuModule],
  exports: [NbContextMenuDirective],
  declarations: [NbContextMenuDirective, ContextMenuComponent],
  entryComponents: [ContextMenuComponent],
})
export class NbContextMenuModule {
}
