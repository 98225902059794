import { Component, HostBinding, Input } from '@angular/core';

import { convertToBoolProperty } from '../helpers';
import { NbComponentSize } from '../component-size';
import { NbComponentStatus } from '../component-status';
import { NbBadgePosition } from '../badge/badge.component';


@Component({
  selector: 'nb-action',
  styleUrls: ['./action.component.scss'],
  template: `
    <ng-container *ngIf="icon; else projectedContent">
      <a class="icon-container"
         [routerLink]="link"
         [title]="title"
         *ngIf="link">
        <nb-icon [icon]="icon"></nb-icon>
      </a>
      <a class="icon-container"
         [href]="href"
         [title]="title"
         *ngIf="href && !link">
        <nb-icon [icon]="icon"></nb-icon>
      </a>
      <a class="icon-container"
         href="#"
         [title]="title"
         *ngIf="!href && !link"
         (click)="$event.preventDefault()">
        <nb-icon [icon]="icon"></nb-icon>
      </a>
    </ng-container>

    <ng-template #projectedContent>
      <ng-content></ng-content>
    </ng-template>

    <nb-badge *ngIf="badgeText"
              [text]="badgeText"
              [status]="badgeStatus"
              [position]="badgePosition">
    </nb-badge>
  `,
})
export class NbActionComponent {

  @Input() link: string;

  @Input() href: string;

  @Input() title: string = '';

  @Input() icon: string;

  @Input()
  @HostBinding('class.disabled')
  get disabled(): boolean {
    return this._disabled;
  }
  set disabled(value: boolean) {
    this._disabled = convertToBoolProperty(value);
  }
  protected _disabled: boolean = false;
  @Input() badgeText: string;

  @Input() badgeStatus: NbComponentStatus;

  @Input() badgePosition: NbBadgePosition;
}

@Component({
  selector: 'nb-actions',
  styleUrls: ['./actions.component.scss'],
  template: `
    <ng-content select="nb-action"></ng-content>
  `,
})
export class NbActionsComponent {

  @Input()
  get size(): NbComponentSize {
    return this._size;
  }
  set size(value: NbComponentSize) {
    this._size = value;
  }
  protected _size: NbComponentSize = 'small';

  @Input()
  @HostBinding('class.full-width')
  get fullWidth(): boolean {
    return this._fullWidth;
  }
  set fullWidth(value: boolean) {
    this._fullWidth = convertToBoolProperty(value);
  }
  protected _fullWidth: boolean = false;

  @HostBinding('class.size-tiny')
  get tiny(): boolean {
    return this.size === 'tiny';
  }

  @HostBinding('class.size-small')
  get small(): boolean {
    return this.size === 'small';
  }

  @HostBinding('class.size-medium')
  get medium(): boolean {
    return this.size === 'medium';
  }

  @HostBinding('class.size-large')
  get large(): boolean {
    return this.size === 'large';
  }

  @HostBinding('class.size-giant')
  get giant(): boolean {
    return this.size === 'giant';
  }
}
