import {
  AfterViewInit,
  ComponentRef,
  Directive,
  ElementRef,
  HostBinding,
  Input,
  Output,
  OnChanges,
  OnDestroy,
  OnInit,
  EventEmitter,
} from '@angular/core';
import {
  filter,
  takeWhile
} from 'rxjs/operators';

import {
  NbDynamicOverlay,
  NbDynamicOverlayController
} from '../cdk/overlay/dynamic/dynamic-overlay';
import {
  NbDynamicOverlayHandler
} from '../cdk/overlay/dynamic/dynamic-overlay-handler';
import {
  NbOverlayRef
} from '../cdk/overlay/mapping';
import {
  NbAdjustableConnectedPositionStrategy,
  NbAdjustment,
  NbPosition
} from '../cdk/overlay/overlay-position';
import {
  NbTrigger
} from '../cdk/overlay/overlay-trigger';
import {
  ContextMenuComponent
} from './context-menu.component';
import {
  NbMenuItem,
  NbMenuService,
  NbMenuBag
} from '../menu/menu.service';


@Directive({
  selector: '[nbContextMenu]',
  providers: [NbDynamicOverlayHandler, NbDynamicOverlay],
})
export class NbContextMenuDirective implements NbDynamicOverlayController, OnChanges, AfterViewInit, OnDestroy, OnInit {

  @HostBinding('class.context-menu-host')
  contextMenuHost = true;

  @Input('nbContextMenuPlacement')
  position: NbPosition = NbPosition.BOTTOM;


  @Input('nbContextMenuAdjustment')
  adjustment: NbAdjustment = NbAdjustment.CLOCKWISE;

  @Input('nbContextMenuTag')
  tag: string;


  @Input('nbContextMenu')
  set items(items: NbMenuItem[]) {
    this.validateItems(items);
    this._items = items;
  };

  @Input('nbContextMenuTrigger')
  trigger: NbTrigger = NbTrigger.CLICK;
  @Output() itemClick = new EventEmitter();

  protected ref: NbOverlayRef;
  protected container: ComponentRef < any > ;
  protected positionStrategy: NbAdjustableConnectedPositionStrategy;
  protected alive: boolean = true;
  private _items: NbMenuItem[] = [];

  private dynamicOverlay: NbDynamicOverlay;

  constructor(private hostRef: ElementRef,
    private menuService: NbMenuService,
    private dynamicOverlayHandler: NbDynamicOverlayHandler) {}

  ngOnInit() {
    this.dynamicOverlayHandler
      .host(this.hostRef)
      .componentType(ContextMenuComponent);
  }

  ngOnChanges() {
    this.rebuild();
  }

  ngAfterViewInit() {
    this.dynamicOverlay = this.configureDynamicOverlay()
      .build();
    this.subscribeOnItemClick();
  }

  rebuild() {
    this.dynamicOverlay = this.configureDynamicOverlay()
      .rebuild();
  }

  show() {
    this.dynamicOverlay.show();
  }

  hide() {
    console.log(this.tag)
    this.dynamicOverlay.hide();
  }

  toggle() {
    this.dynamicOverlay.toggle();
  }

  onItemClick(item: NbMenuBag) {
    this.itemClick.emit(item);
  }

  ngOnDestroy() {
    this.dynamicOverlayHandler.destroy();
  }

  protected configureDynamicOverlay() {
    return this.dynamicOverlayHandler
      .position(this.position)
      .trigger(this.trigger)
      .adjustment(this.adjustment)
      .context({
        position: this.position,
        items: this._items,
        tag: this.tag,
      });
  }

   private validateItems(items: NbMenuItem[]) {
    if (!items || !items.length) {
      throw Error(`List of menu items expected, but given: ${items}`)
    }
  }

  private subscribeOnItemClick() {
    this.menuService.onItemClick()
      .pipe(
        takeWhile(() => this.alive),
        filter(({
          tag
        }) => tag === this.tag),
      )
      .subscribe(res => this.onItemClick(res));
  }
}
