import { Injectable } from '@angular/core';

import { AuthToken } from './token';
import { AuthTokenParceler } from './token-parceler';

export abstract class TokenStorage {
  abstract get(): AuthToken;
  abstract set(token: AuthToken);
  abstract clear();
}


@Injectable()
export class TokenLocalStorage extends TokenStorage {

  protected key = 'authenticatedByLoginToken';

  constructor(private parceler: AuthTokenParceler) {
    super();
  }

  public get(): AuthToken {
    const raw = localStorage.getItem(this.key);
    return this.parceler.unwrap(raw);
  }


  public set(token: AuthToken) {
    const raw:any = this.parceler.wrap(token);
    localStorage.setItem(this.key, raw);
  }

  public clear() {
    localStorage.removeItem(this.key);
  }
}
