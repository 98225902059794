import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AppService } from './app/app.service';

export interface ILookupListEntry {
  lookupIndex?: number;
  name: string;
  isDisabled?: boolean;
  description?: string;
}

export interface ILookupList {
  name: string;
  listEntries: ILookupListEntry;
}

export interface ILookupRequest extends ILookupListEntry {
  tableName: string;
}

@Injectable()
export class LookupService {
  public apiPath: string = 'http://localhost:63753/api/v1/lookuplists';
  public locale: string = 'en-us';

  constructor(public Http: HttpClient,private appService: AppService) {}

  public get(): Promise<ILookupList[]> {
    return this.Http.get<ILookupList[]>(`${this.appService.apiUrl}lookuplists/generic/${this.locale}`)
      .toPromise();
  }

  public add(request: ILookupRequest): Promise<ILookupList> {
    return this.Http.post<ILookupList>(`${this.appService.apiUrl}lookuplists`, request)
      .toPromise();
  }

  public update(request: ILookupRequest): Promise<ILookupList> {
    return this.Http.put<ILookupList>(`${this.appService.apiUrl}lookuplists/update`, request)
      .toPromise();
  }

  public delete(tableName: string, lookupIndex: number): Promise<{}> {
    return this.Http.delete(`${this.appService.apiUrl}lookuplists/${tableName}/${lookupIndex}`)
      .toPromise();
  }
}
