import { TranslationWidth } from '@angular/common';


export abstract class NbDateService<D> {
  readonly DAYS_IN_WEEK: number = 7;

  protected locale: string;

  setLocale(locale: any) {
    this.locale = locale;
  }

  isBetween(date: D, start: D, end: D): boolean {
    return this.compareDates(date, start) > 0 && this.compareDates(date, end) < 0;
  };

  isSameDaySafe(date1: D, date2: D): boolean {
    return date1 && date2 && this.isSameDay(date1, date2);
  };

  isSameMonthSafe(date1: D, date2: D): boolean {
    return date1 && date2 && this.isSameMonth(date1, date2);
  }

  isSameYearSafe(date1: D, date2: D): boolean {
    return date1 && date2 && this.isSameYear(date1, date2);
  }


  abstract isValidDateString(date: string, format: string): boolean;

  abstract today(): D;

  abstract getDate(date: D): number;

  abstract getMonth(date: D): number;

  abstract getYear(date: D): number;

  abstract getDayOfWeek(date: D): number;

  abstract getFirstDayOfWeek(): number;

  abstract getMonthName(date: D, style?: TranslationWidth): string;

  abstract getMonthNameByIndex(month: number, style?: TranslationWidth): string;


  abstract getDayOfWeekNames(style?: TranslationWidth): string[];

  abstract parse(date: string, format: string): D;

  abstract format(date: D, format: string): string;

  abstract createDate(year: number, month: number, date: number): D;

  abstract isSameYear(date1: D, date2: D): boolean;

  abstract isSameMonth(date1: D, date2: D): boolean;

  abstract isSameDay(date1: D, date2: D): boolean;

  abstract compareDates(date1: D, date2: D): number;

  abstract clone(date: D): D;

  abstract getMonthStart(date: D): D;

  abstract getMonthEnd(date: D): D;

  abstract getYearStart(date: D): D;

  abstract getYearEnd(date: D): D;

  abstract getNumberOfDaysInMonth(date: D): number;

  abstract addDay(date: D, days: number): D;

  abstract addMonth(date: D, months: number): D;

  abstract addYear(date: D, years: number): D;

  abstract getId(): string;
}
