import { Component } from '@angular/core';
import { NbDialogRef } from '../../../common/public_api';

@Component({
  selector: 'ngx-add-role-modal',
  templateUrl: 'add-role-modal.component.html',
  styleUrls: ['add-role-modal.component.scss'],
})
export class AddRoleModalComponent {

  constructor(protected ref: NbDialogRef<AddRoleModalComponent>) {}

  public cancel() {
    this.ref.close();
  }

  public submit(name) {
    this.ref.close(name);
  }
}
