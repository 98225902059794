import { Component, Input } from '@angular/core';
import { NbMenuItem } from '../menu/menu.service';
import { NbPositionedContainer, NbRenderableContainer } from '../cdk/overlay/overlay-container';


@Component({
  selector: 'nb-context-menu',
  template: `
    <nb-menu class="context-menu" [items]="context.items" [tag]="context.tag"></nb-menu>
  `,
})
export class ContextMenuComponent extends NbPositionedContainer implements NbRenderableContainer {

  @Input() items: NbMenuItem[] = [];
  @Input() tag: string;

  @Input()
  context: { items: NbMenuItem[], tag?: string } = { items: [] };

  renderContent() {}
}
