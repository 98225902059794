import { Component, HostBinding, Input } from '@angular/core';

import { NbComponentStatus } from '../component-status';

export type NbBadgePhysicalPosition = 'top left' | 'top right' | 'bottom left' | 'bottom right';
export type NbBadgeLogicalPosition = 'top start' | 'top end' | 'bottom start' | 'bottom end';
export type NbBadgePosition = NbBadgePhysicalPosition | NbBadgeLogicalPosition;

@Component({
  selector: 'nb-badge',
  styleUrls: ['./badge.component.scss'],
  template: `{{text}}`,
})
export class NbBadgeComponent {
  @Input() text: string = '';

  @Input()
  get position(): NbBadgePosition {
    return this._position;
  }
  set position(value: NbBadgePosition) {
    this._position = value || this._defaultPosition;
  }
  protected _defaultPosition: NbBadgePosition = 'top right';
  protected _position: NbBadgePosition = this._defaultPosition;

  @Input() status: NbComponentStatus = 'primary';

  @HostBinding('class.status-primary')
  get primary(): boolean {
    return this.status === 'primary';
  }

  @HostBinding('class.status-success')
  get success(): boolean {
    return this.status === 'success';
  }

  @HostBinding('class.status-info')
  get info(): boolean {
    return this.status === 'info';
  }

  @HostBinding('class.status-warning')
  get warning(): boolean {
    return this.status === 'warning';
  }

  @HostBinding('class.status-danger')
  get danger(): boolean {
    return this.status === 'danger';
  }

  @HostBinding('class.position-top')
  get top(): boolean {
    return this.position.includes('top');
  }

  @HostBinding('class.position-right')
  get right(): boolean {
    return this.position.includes('right');
  }

  @HostBinding('class.position-bottom')
  get bottom(): boolean {
    return this.position.includes('bottom');
  }

  @HostBinding('class.position-left')
  get left(): boolean {
    return this.position.includes('left');
  }

  @HostBinding('class.position-start')
  get start(): boolean {
    return this.position.includes('start');
  }

  @HostBinding('class.position-end')
  get end(): boolean {
    return this.position.includes('end');
  }
}
