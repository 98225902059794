import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostBinding,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  Type,
} from '@angular/core';

import { NbCalendarMonthModelService } from '../../services/calendar-month-model.service';
import { NbCalendarDayCellComponent } from './calendar-day-cell.component';
import { NbCalendarCell, NbCalendarSize } from '../../model';


/**
 * Provides capability pick days.
 * */
@Component({
  selector: 'nb-calendar-day-picker',
  styles: [` :host { display: block; } `],
  template: `
    <nb-calendar-days-names></nb-calendar-days-names>
    <nb-calendar-picker
      [data]="weeks"
      [visibleDate]="visibleDate"
      [selectedValue]="date"
      [cellComponent]="cellComponent"
      [min]="min"
      [max]="max"
      [filter]="filter"
      (select)="onSelect($event)">
    </nb-calendar-picker>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NbCalendarDayPickerComponent<D, T> implements OnChanges {

  @Input() visibleDate: D;

  @Input() boundingMonths: boolean = true;

  @Input() min: D;

  @Input() max: D;

  @Input() filter: (D) => boolean;

  @Input('cellComponent')
  set setCellComponent(cellComponent: Type<NbCalendarCell<D, T>>) {
    if (cellComponent) {
      this.cellComponent = cellComponent;
    }
  }
  cellComponent: Type<NbCalendarCell<any, any>> = NbCalendarDayCellComponent;

  @Input() size: NbCalendarSize = NbCalendarSize.MEDIUM;

  @Input() date: T;

  @Output() dateChange = new EventEmitter<D>();

  @HostBinding('class.medium')
  get medium() {
    return this.size === NbCalendarSize.MEDIUM;
  }

  @HostBinding('class.large')
  get large() {
    return this.size === NbCalendarSize.LARGE;
  }
  weeks: D[][];

  constructor(private monthModel: NbCalendarMonthModelService<D>) {
  }

  ngOnChanges({ visibleDate }: SimpleChanges) {
    if (visibleDate) {
      this.weeks = this.monthModel.createDaysGrid(this.visibleDate, this.boundingMonths);
    }
  }

  onSelect(day: D) {
    this.dateChange.emit(day);
  }
}
