import {
  Component,
  Inject,
  OnInit
} from '@angular/core';
import {
  Router
} from '@angular/router';

import {
  AUTH_OPTIONS
} from '../auth.options';
import {
  getDeepFromObject
} from '../helpers';
import {
  AuthService
} from '../services/auth.service';
import {
  AuthResult
} from '../services/auth-result';

@Component({
  selector: 'nb-logout',
  templateUrl: './logout.component.html',
})
export class NbLogoutComponent implements OnInit {

  redirectDelay: number = 0;
  strategy: string = '';

  constructor(protected service: AuthService,
    @Inject(AUTH_OPTIONS) protected options = {},
    protected router: Router) {
    this.redirectDelay = this.getConfigValue('forms.logout.redirectDelay');
    this.strategy = this.getConfigValue('forms.logout.strategy');
  }

  ngOnInit(): void {
    this.logout();
  }

  logout(): void {
    this.service.logout().subscribe((result: AuthResult) => {

      const redirect = result.getRedirect();
      if (redirect) {
        setTimeout(() => {
          return this.router.navigateByUrl(redirect);
        }, this.redirectDelay);
      }
    });
  }

  getConfigValue(key: string): any {
    return getDeepFromObject(this.options, key, null);
  }
}
