import { Component, OnInit } from '@angular/core';
import {
  NbToastrService,
  NbDialogService,
} from '../../common/public_api';
import { MENU_ITEMS } from '../../pages-menu';
import { PatientService, IIdentityData } from '../../auth/services/patient.service';
import * as moment from 'moment';
import * as _ from 'lodash';
import { ILookupList, LookupService } from '../../auth/services/lookup.service';

@Component({
  selector: 'ngx-patients',
  templateUrl: './patients.component.html',
  styleUrls: ['./patients.component.scss'],
})
export class PatientsComponent implements OnInit {

  private menu = MENU_ITEMS;
  public lookups: ILookupList[] = [];
  public defaultColumns = [
    'Name',
    'CNIC',
    'DOB',
    'Email',
    'State',
    'contact #',
    'Status',
    // 'Action',
  ];

  public patients: IIdentityData[];
  public copyPatients: IIdentityData[];
  public loading = false;
  public searchInput: string = '';

  public constructor(
    private dialogService: NbDialogService,
    private ToastService: NbToastrService,
    private patientService: PatientService,
    public LookupsService: LookupService
  ) { }

  public ngOnInit() {
    this.loading = true;
    this.populatePatients();
    this.populateLookups();
  }

  private populatePatients() {
    this.patientService.getPatientsList()
      .then((response: any) => {
        this.loading = false;
        this.patients = response.items;
        this.copyPatients = response.items;
      })
      .catch(() => {
        this.loading = false;
        this.ToastService.danger('Failed to get Patients List');
      });
  }

  private populateLookups() {
    this.LookupsService.get()
      .then(response => this.lookups = response)
      .catch(() => this.ToastService.danger('Failed to get Lookup list.'));
  }

  public getStateNamyByLookup(lookupId): string {
    if (_.isEmpty(this.lookups) || _.isNil(lookupId)) {
      return null;
    }

    const states: any = this.lookups.find(item => item.name === 'State').listEntries
    const state = states.find(state => state.lookupIndex === lookupId)

    if(_.isNil(state)) {
      return null;
    }

    return state.name;
  }

  public clear() {
    this.searchInput = '';
    this.patients = this.copyPatients;
  }

  public getDate(date: string | Date): any {
    if (_.isNil(date)) {
      return null;
    }

    return moment(date).format('DD/MM/YYYY');
  }

  public search() {
    this.patients = this.copyPatients
      .filter(patient => patient.firstname.toLowerCase().startsWith(this.searchInput.toLowerCase())
        || patient.lastname.toLowerCase().startsWith(this.searchInput.toLowerCase())
        || patient.firstname.toLowerCase().endsWith(this.searchInput.toLowerCase())
        || patient.lastname.toLowerCase().endsWith(this.searchInput.toLowerCase()));
  }
}
