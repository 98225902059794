import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AppService } from './app/app.service';

export interface IIdentityBasicInfo {
  firstname: string;
  lastname: string;
  password?: string;
  genderTypeLookup?: number;
  identityTypeLookup?: number;
  email: string;
  loginProviderName?: string;
  providerKey?: string;
  profilePictureTitle?: string;
  profilePicturePath?: string;
  isActive?: boolean;
}

export interface IIdentityData extends IIdentityBasicInfo {
  identityID: number;
  address1: string;
  address2: string;
  city: string;
  province: string;
  cnic: string;
  phoneNumber: string;
  mobileNumber: string;
  allowAuthentication: boolean | null;
  statusId: number | null;
  membershipTypeLookup: number;
  creationtionDate: string;
  creatorIdentityID: number | null;
}

@Injectable()
export class PatientService {
  public apiPath: string = 'http://localhost:63753/api/v1/patient';

  constructor(public Http: HttpClient, private appService: AppService) { }

  public getPatientsList(query: string = ''): Promise<IIdentityData[]> {
    return this.Http.get<IIdentityData[]>(`${this.appService.apiUrl}patient/patientlist` + query)
      .toPromise();
  }

  public add(request: IIdentityData): Promise<IIdentityData> {
    return this.Http.post<IIdentityData>(`${this.appService.apiUrl}patient`, request)
      .toPromise();
  }

  // public publish(request: IPublishDoctorProfileRequest): Promise<IPublishDoctorProfileResponse> {
  //   return this.Http.post<IPublishDoctorProfileResponse>(`${this.apiPath}/publishProfile`, request)
  //     .toPromise();
  // }
}
