import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AppService } from './app/app.service';

export interface ISurvey {
  surveyID: number;
  surveyTitle: string;
  startDate: string;
  endDate: string;
  isOpen: boolean | null;
  description: string;
  surveyTypeLookup: number;
  surveyCreatorIdentityID: number;
  creationDate: string | null;
  modificationDate: string | null;
  isPublished: boolean | null;
  isPublishRequested: boolean | null;
  isArchived: boolean | null;
}

export interface IPublishSurveyRequest {
  surveyID: number;
  publish: boolean | null;
  surveyCreatorIdentityID: number;
  isPublished: boolean | null;
  isPublishRequested: boolean | null;
}

export interface IPublishSurveyResponse {
  completed: boolean;
}

@Injectable()
export class SurveyService {
  public apiPath: string = 'http://localhost:63753/api/v1/survey';

  constructor(public Http: HttpClient,private appService: AppService) {}

  public getSurveyList(query: string = ''): Promise<{items: ISurvey[], count: number}> {
    return this.Http.get<{items: ISurvey[], count: number}>(`${this.appService.apiUrl}survey/list` + query)
      .toPromise();
  }

  public publishSurveyByAdmin(request: IPublishSurveyRequest): Promise<IPublishSurveyResponse> {
    return this.Http.post<IPublishSurveyResponse>(`${this.appService.apiUrl}survey/publish`, request)
      .toPromise();
  }
}
