import {
  Component,
  Input,
  Output,
  EventEmitter,
  OnInit,
  OnDestroy,
  AfterViewInit,
  Inject,
  DoCheck,
} from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { takeWhile, filter, map } from 'rxjs/operators';
import { NbMenuInternalService, NbMenuItem, NbMenuBag, NbMenuService } from './menu.service';
import { convertToBoolProperty } from '../helpers';
import { NB_WINDOW } from '../../ngx-common.options';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { NbLayoutDirectionService } from '../../services/direction.service';

export enum NbToggleStates {
  Expanded = 'expanded',
    Collapsed = 'collapsed',
}

@Component({
  selector: '[nbMenuItem]',
  templateUrl: './menu-item.component.html',
  animations: [
    trigger('toggle', [
      state(NbToggleStates.Collapsed, style({
        height: '0',
        margin: '0',
      })),
      state(NbToggleStates.Expanded, style({
        height: '*',
      })),
      transition(`${NbToggleStates.Collapsed} <=> ${NbToggleStates.Expanded}`, animate(300)),
    ]),
  ],
})
export class NbMenuItemComponent implements DoCheck, AfterViewInit, OnDestroy {
  @Input() menuItem = < NbMenuItem > null;

  @Output() hoverItem = new EventEmitter < any > ();
  @Output() toggleSubMenu = new EventEmitter < any > ();
  @Output() selectItem = new EventEmitter < any > ();
  @Output() itemClick = new EventEmitter < any > ();

  protected alive = true;
  toggleState: NbToggleStates;

  constructor(protected menuService: NbMenuService,
    protected directionService: NbLayoutDirectionService) {}

  ngDoCheck() {
    this.toggleState = this.menuItem.expanded ? NbToggleStates.Expanded : NbToggleStates.Collapsed;
  }

  ngAfterViewInit() {
    this.menuService.onSubmenuToggle()
      .pipe(
        takeWhile(() => this.alive),
        filter(({
          item,
        }) => item === this.menuItem),
        map(({
          item,
        }: NbMenuBag) => item.expanded),
      )
      .subscribe(isExpanded => this.toggleState = isExpanded ? NbToggleStates.Expanded : NbToggleStates.Collapsed);
  }

  ngOnDestroy() {
    this.alive = false;
  }

  onToggleSubMenu(item: NbMenuItem) {
    this.toggleSubMenu.emit(item);
  }

  onHoverItem(item: NbMenuItem) {
    this.hoverItem.emit(item);
  }

  onSelectItem(item: NbMenuItem) {
    this.selectItem.emit(item);
  }

  onItemClick(item: NbMenuItem) {
    this.itemClick.emit(item);
  }

  getExpandStateIcon(): string {
    if (this.menuItem.expanded) {
      return 'chevron-down-outline';
    }

    return this.directionService.isLtr() ?
      'chevron-left-outline' :
      'chevron-right-outline';
  }
}

@Component({
  selector: 'nb-menu',
  styleUrls: ['./menu.component.scss'],
  template: `
    <ul class="menu-items">
      <ng-container *ngFor="let item of items">
        <li nbMenuItem *ngIf="!item.hidden"
            [menuItem]="item"
            [class.menu-group]="item.group"
            (hoverItem)="onHoverItem($event)"
            (toggleSubMenu)="onToggleSubMenu($event)"
            (selectItem)="onSelectItem($event)"
            (itemClick)="onItemClick($event)"
            class="menu-item">
        </li>
      </ng-container>
    </ul>
  `,
})
export class NbMenuComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() tag: string;

  @Input() items: NbMenuItem[];

  @Input()
  get autoCollapse(): boolean {
    return this._autoCollapse;
  }
  set autoCollapse(value: boolean) {
    this._autoCollapse = convertToBoolProperty(value);
  }
  protected _autoCollapse: boolean = false;

  protected alive: boolean = true;

  constructor(@Inject(NB_WINDOW) protected window,
    protected menuInternalService: NbMenuInternalService,
    protected router: Router) {}

  ngOnInit() {
    this.menuInternalService.prepareItems(this.items);

    this.menuInternalService
      .onAddItem()
      .pipe(
        takeWhile(() => this.alive),
        filter((data: {
          tag: string; items: NbMenuItem[]
        }) => this.compareTag(data.tag)),
      )
      .subscribe(data => this.onAddItem(data));

    this.menuInternalService
      .onNavigateHome()
      .pipe(
        takeWhile(() => this.alive),
        filter((data: {
          tag: string; items: NbMenuItem[]
        }) => this.compareTag(data.tag)),
      )
      .subscribe(() => this.navigateHome());

    this.menuInternalService
      .onGetSelectedItem()
      .pipe(
        takeWhile(() => this.alive),
        filter((data: {
          tag: string; listener: BehaviorSubject < NbMenuBag >
        }) => this.compareTag(data.tag)),
      )
      .subscribe((data: {
        tag: string; listener: BehaviorSubject < NbMenuBag >
      }) => {
        data.listener.next({
          tag: this.tag,
          item: this.getSelectedItem(this.items),
        });
      });

    this.menuInternalService
      .onCollapseAll()
      .pipe(
        takeWhile(() => this.alive),
        filter((data: {
          tag: string,
        }) => this.compareTag(data.tag)),
      )
      .subscribe(() => this.collapseAll());

    this.router.events
      .pipe(
        takeWhile(() => this.alive),
        filter(event => event instanceof NavigationEnd),
      )
      .subscribe(() => {
        this.menuInternalService.selectFromUrl(this.items, this.tag, this.autoCollapse);
      });
  }

  ngAfterViewInit() {
    setTimeout(() => this.menuInternalService.selectFromUrl(this.items, this.tag, this.autoCollapse));
  }

  onAddItem(data: {
    tag: string; items: NbMenuItem[]
  }) {
    this.items.push(...data.items);

    this.menuInternalService.prepareItems(this.items);
    this.menuInternalService.selectFromUrl(this.items, this.tag, this.autoCollapse);
  }

  onHoverItem(item: NbMenuItem) {
    this.menuInternalService.itemHover(item, this.tag);
  }

  onToggleSubMenu(item: NbMenuItem) {
    if (this.autoCollapse) {
      this.menuInternalService.collapseAll(this.items, this.tag, item);
    }
    item.expanded = !item.expanded;
    this.menuInternalService.submenuToggle(item, this.tag);
  }

  // TODO: is not fired on page reload
  onSelectItem(item: NbMenuItem) {
    this.menuInternalService.selectItem(item, this.items, this.autoCollapse, this.tag);
  }

  onItemClick(item: NbMenuItem) {
    this.menuInternalService.itemClick(item, this.tag);
  }

  ngOnDestroy() {
    this.alive = false;
  }

  protected navigateHome() {
    const homeItem = this.getHomeItem(this.items);

    if (homeItem) {
      if (homeItem.link) {
        this.router.navigate([homeItem.link], {
          queryParams: homeItem.queryParams,
          fragment: homeItem.fragment,
        });
      }

      if (homeItem.url) {
        this.window.location.href = homeItem.url;
      }
    }
  }

  protected collapseAll() {
    this.menuInternalService.collapseAll(this.items, this.tag);
  }

  protected getHomeItem(items: NbMenuItem[]): NbMenuItem {
    for (const item of items) {
      if (item.home) {
        return item;
      }

      const homeItem = item.children && this.getHomeItem(item.children);
      if (homeItem) {
        return homeItem;
      }
    }
  }

  protected compareTag(tag: string) {
    return !tag || tag === this.tag;
  }

  protected getSelectedItem(items: NbMenuItem[]): NbMenuItem {
    let selected = null;
    items.forEach((item: NbMenuItem) => {
      if (item.selected) {
        selected = item;
      }
      if (item.selected && item.children && item.children.length > 0) {
        selected = this.getSelectedItem(item.children);
      }
    });
    return selected;
  }
}
